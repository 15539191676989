import PlanPicker from "./steps/PlanPicker";
import NewOrExistingClient from "./steps/NewOrExistingClient";
import Species from "./steps/Species";
import SpayNeuter from "./steps/SpayNeuter"
import SearchByEmailStep from "./steps/SearchByEmailStep";
import PetSize from "./steps/PetSize";
import PetName from "./steps/PetName";
import PetAgeAndSex from './steps/PetAgeAndSex';
import NewClientStep from './steps/NewClientStep';
import UserNotFoundStep from './steps/UserNotFoundStep';
import SelectPet from "./steps/SelectPet";
import PersonalDetailsStep from './steps/PersonalDetailsStep';
import CheckoutStep from './steps/CheckoutStep';
import AgreementStep from './steps/AgreementStep';
import Review from './steps/Review'
import LoadingStep from './steps/LoadingStep';
import DayJs from 'dayjs';
import Wizard from '../../components/wizard/Wizard';
import { useNavigate, useParams } from 'react-router-dom';
import PlanReceipt from "../PlanReceipt/PlanReceipt";
import ErrorStep from "./steps/ErrorStep";

function ExampleWizardFlow() {

  const navigate = useNavigate();

  const wizardDefinition = {
    steps: {

      loading: { component: LoadingStep,
        actions: {
          loadVet: (state, action) => {
            state.vet = action.vet;
            if (state.vet.has_core_plan) {
              const slug = window.location.href.split('/').pop();
              if (window.location.href.slice(0, 21) === 'http://localhost:3000') {
                window.location.href = `http://localhost:5173/enroll/${slug}`;
              } else if (window.location.href.slice(0, 17) === 'https://admin-dev') {
                window.location.href = `https://admin-dev.snoutid.com/enroll/${slug}`;
              } else {
                window.location.href = `https://admin.snoutplans.com/enroll/${slug}`;
              }
              return; // don't reveal the step - stay in a loading state
            }
            state.plans = action.vet.plans;
            state.wizard.currentStep = 'newOrExistingClient'
          },
          loadVetError: (state, action) => {
            state.wizard.currentStep = 'error'
          }
        }
      },

      newOrExistingClient: { component: NewOrExistingClient,
        actions: {
          next: (state, action) => {
            state.wizard.currentStep = action.payload.answer === 'yes' ? 'searchByEmailStep' : 'newClientStep';
          },
        }
      },

      searchByEmailStep: { component: SearchByEmailStep,
        actions: {
          next: (state, action) => {
            state.client = action.client;
            state.applicablePatients = action.applicablePatients;
            if (action.client?.id) {
              if (action.client?.patients?.length) {
                state.wizard.currentStep = 'selectPet';
              } else {
                state.wizard.currentStep = 'petName'
              }
            } else {
              state.wizard.currentStep = 'userNotFoundStep';
            }
          },
          prev: 'newOrExistingClient'
        }
      },

      userNotFoundStep: { component: UserNotFoundStep,
        actions: {
          next: 'newClientStep',
          prev: 'searchByEmailStep'
        }
      },

      newClientStep: { component: NewClientStep,
        actions: {
          next: (state, action) => {
            state.client = action.client;
            state.wizard.currentStep = 'petName';
          },
          prev: 'newOrExistingClient'
        }
      },

      selectPet: { component: SelectPet,
        actions: {
          next: (state, action) => {
            state.currentPet = action.selectedPet;
            state.wizard.currentStep = 'petName';
          },
          prev: 'searchByEmailStep'
        }
      },

      petName: { component: PetName,
        actions: {
          next: (state, action) => {
            if (state.currentPet) {
              state.currentPet.name = action.petName;
            } else {
              state.currentPet ={ name: action.petName };
          }
            state.wizard.currentStep = 'species';
          },
          prev: (state, action) => {
            let prevStep;
            if (state?.currentPet?.id) {
              prevStep = 'selectPet';
            } else {
              if (state?.client?.id) {
                if (state?.applicablePatients?.length) prevStep = 'selectPet';
                else prevStep = 'searchByEmailStep';
              } else {
                prevStep = 'newClientStep';
              }
            }
            state.wizard.currentStep = prevStep;
          }
        }
      },

      species: { component: Species,
        actions: {
          next: (state, action) => {
            state.currentPet.species = action.species;
            state.speciesPlans = state.plans.filter(plan => plan.species === action.species || !plan.species);
            state.wizard.currentStep = 'ageAndSex';
          },
          prev: 'petName'
        }
      },

      ageAndSex: { component: PetAgeAndSex,
        actions: {
          next: (state, { birthdate, sex }) => {
            state.currentPet.birthdate = birthdate;
            state.currentPet.sex = sex;
            state.wizard.currentStep = 'petSize';
          },
          prev: 'species'
        }
      },

      petSize: { component: PetSize,
        actions: {
          next: (state, action) => {
            state.currentPet.size = action.size;
            state.wizard.currentStep = 'spayNeuter';
          },
          prev: 'ageAndSex'
        }
      },

      spayNeuter: { component: SpayNeuter,
        actions: {
          next: (state, action) => {
            state.currentPet.spayNeuter = action.spayNeuter;
            state.wizard.currentStep = 'review';
          },
          prev: 'petSize'
        }
      },

      review: { component: Review,
        actions: {
          next: (state, action) => {
            state.wizard.currentStep = 'planPicker';
          },
          prev: 'spayNeuter'
        }
      },

      planPicker: { component: PlanPicker,
        actions: {
          next: (state, action) => {
            state.selectedPlan = action.selectedPlan;
            state.wizard.currentStep = 'personalDetailsStep';
          },
          prev: 'review',
        }
      },

      personalDetailsStep: { component: PersonalDetailsStep,
        actions: {
          next: (state, action) => {
            state.personalDetails = action.personalDetails;
            state.wizard.currentStep = 'checkout';
          },
          prev: 'planPicker',
        }
      },

      checkout: { component: CheckoutStep,
        actions: {
          next: (state, action) => {
            navigate(`/receipt/careplan/${action.planInstanceId}`)
          },
          prev: 'personalDetailsStep',
          showTos: (state, action) => {
            state.paymentMethod = action.formState;
            state.wizard.currentStep = 'agreementStep';
          },
        }
      },

      agreementStep: { component: AgreementStep,
        actions: {
          prev: 'checkout',
        }
      },

      receiptStep: { component: PlanReceipt,
        actions: {
          prev: 'newOrExistingClient',
        }
      },

      error: { component: ErrorStep }
    }
  };

  const params = useParams();

  return (
    <Wizard definition={wizardDefinition} initialState={{ vetSlug: params.slug }}/>
  );
}

export default ExampleWizardFlow;
