import common from '../lib/common';

const BASE_URL = window.location.protocol === 'http:' ? `http://${window.location.hostname}:3001` : window.location.origin + '/api'

// wrapping fetch so that http errors reject rather than resolving
const fatch = (...args) => {
  return new Promise((resolve, reject) => {
    fetch(...args)
        .then((result) => {
          if (!result?.ok) {
            // set default error message for api failures
            result.message = 'Whoops! Something went wrong. Please refresh or try again later';
            if (result.headers.get('Content-Type').indexOf('application/json') !== -1) {

              result.json().then((body) => {
                if (body.message) {
                  result.message = body.message

                }
                reject(result);
              });
            } else {
              reject(result);
            }
            return;
          }
          if (result.status !== 204 && result.headers.get('Content-Type').indexOf('application/json') !== -1) {
            result.json().then((body) => { resolve(body)});
          } else {
            resolve();
          }
        })
        .catch((error) => {
          // set default error message for api failures
          if (!error.message) error.message = 'Whoops! Something went wrong. Please refresh or try again later';
          reject(error);
        });
  });
};

function get(uri) {
  return fatch(`${BASE_URL}${uri}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    }
  });
}

// fatch patch
function patch(uri, body) {
  return fatch(`${BASE_URL}${uri}`, {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
}

function post(uri, body) {
  return fatch(`${BASE_URL}${uri}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
}

function authenticatedGet(uri) {
  const token = localStorage.getItem('token');
  return fatch(`${BASE_URL}${uri}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });
}

function authenticatedPost(uri, body) {
  const token = localStorage.getItem('token');
  return fatch(`${BASE_URL}${uri}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
}

function authenticatedPut(uri, body) {
  const token = localStorage.getItem('token');
  return fatch(`${BASE_URL}${uri}`, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
}

function authenticatedPatch(uri, body) {
  const token = localStorage.getItem('token');
  return fatch(`${BASE_URL}${uri}`, {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
}


function authenticatedDelete(uri) {
  const token = localStorage.getItem('token');
  return fatch(`${BASE_URL}${uri}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });
}

const api = {
  getWellnessInvitation: async (code) => {
    return get(`/invitation/${code}`);
  },

  createWellnessSubscription: async (code, planId, paymentMethod, personalDetails) => {
    return post(`/invitation/${code}/enroll`, {
      plan_id: planId, card: paymentMethod, personalDetails });
  },

   getInvoiceDagwood: async (id) => {
     const response = await fetch(`http://localhost:3001/dagwood/${id}`, {method: 'GET'});
     return response.json();
  },

  payInvoice: async (invoiceId, invoiceUpdated, selectedPlanId, selectedPaymentMethod) => {
    return new Promise((resolve) => {
      // EXAMPLE PAYMENT ERROR BELOW
      // throw { type: 'payment', message: 'There was something wrong with processing your card. Change your method of payment and try again.' };

      api.getInvoiceDagwood(invoiceId).then(dagwood => {
        dagwood.invoice.state = 'paid';
        dagwood.plan = dagwood.invoice.plans[0];
        dagwood.payment = {
          amount: "$190",
          id: "AS234SDF",
          date: "July 19th, 2023",
          method: {
            provider: "VISA",
            number: "1111"
          }
        };
        resolve(dagwood);
      });
    });
  },

  searchClientsForPractice: async (query) => {
    const response = await authenticatedGet(`/vet_portal/clients?q=${query}`);
    return response;
  },

  clientGetPaymentRequest: async (requestId) => {
    const response = await get(`/payment_request/${requestId}`);
    return response;
  },

  practiceGetPaymentRequest: async (requestId) => {
    const response = await authenticatedGet(`/vet_portal/payment_request/${requestId}`);
    return response;
  },

  createPaymentRequest: async (client, patients, invoice_amount) => {
    const response = await authenticatedPost(`/vet_portal/payment_request`, {
      client, patients, invoice_amount
    });
    return response;
  },

  clientUpdatePaymentRequest: async (id, card) => {
    const response = await patch(`/payment_request/${id}`, { card });
    return response;
  },

  practiceUpdatePaymentRequest: async (id, card) => {
    const response = await authenticatedPatch(`/vet_portal/payment_request/${id}`, {
      card
    });
    return response;
  },

  sendPaymentRequestEmail: async (id, email) => {
    const response = await authenticatedPost(`/vet_portal/payment_request/${id}/email`, {
      email,
    });
    return response;
  },

  sendPaymentRequestSMS: async (id, phone) => {
    const response = await authenticatedPost(`/vet_portal/payment_request/${id}/sms`, {
      phone,
    });
    return response;
  },

  sendInvitationEmail: async (friendlyId, email) => {
    const response = await authenticatedPost(`/vet_portal/invitation/${friendlyId}/email`, {
      email
    });
    return response;
  },


  sendInvitationSMS: async (friendlyId, phone) => {
    const response = await authenticatedPost(`/vet_portal/invitation/${friendlyId}/sms`, {
      phone
    });
    return response;
  },

  checkClientEmail: async (email) => {
    const response = await authenticatedPost(`/vet_portal/client_email_check`, {
      clientEmail: email
    });
    return response.isAvailable;
  },

  createInvitation: async (client, patient, plan_ids, employee_name) => {
    const response = await authenticatedPost(`/vet_portal/invitation/`, {
      client, patient, plan_ids, employee_name,
    });
    return response;
  },

  getPracticeInfo: async () => {
    const response = await authenticatedGet(`/vet_portal/me/`);
    return response;
  },

  getPlansForPractice: async () => {
    const response = await authenticatedGet(`/vet_portal/plans/`);
    return response;
  },

  getClientPlan: async (external_id) => {
    const response =  fatch(`${BASE_URL}/plan_instance/${external_id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    });
    return response;
  },

  updatePlanPaymentMethod: async (external_id, payment_method) => {
    const response =  await fatch(`${BASE_URL}/plan_instance/${external_id}/card`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payment_method),
    });
    return response;
  },

  getPlanInstanceUsageForPractice: (id) => {
    return authenticatedGet(`/vet_portal/plan_instance/${id}/usage`);
  },

  generateDemoDagwood: async (patient_id) => {
      // return post(`/demo_data/${patient_id}`, {});
      const response = await fetch(`http://localhost:3001/demo_data/${patient_id}`, {method: 'POST', body: {}});
      return response.json();
  },

  // Auth stuff
  login: (email, password) => {
    return post('/login', { email, password });
  },
  createPasswordResetLink: (email) => {
    return post('/reset_token', { email });
  },
  resetPassword: (resetToken, password) => {
    return post('/password', { reset_password_token: resetToken, password})
  },

  // Vet management
  getVets: () => {
    return authenticatedGet('/admin/vets');
  },
  createVet: (data) => {
    return authenticatedPost('/admin/vet', data);
  },
  updateVet: (vet_id, data) => {
    return authenticatedPut(`/admin/vet/${vet_id}`, data);
  },
  getVetDetails: (vet_id) => {
    return authenticatedGet(`/admin/vet/${vet_id}`);
  },
  getVetCodes: (vet_id) => {
    return authenticatedGet(`/admin/vet/${vet_id}/codes`);
  },

  // Plan management
  getPlans: (vet_id, addons = false) => {
    return authenticatedGet(`/admin/vet/${vet_id}/plans?addons=${addons}`);
  },
  createPlan: (vet_id, data) => {
    return authenticatedPost(`/admin/vet/${vet_id}/plan`, data);
  },
  deletePlan: (plan_id) => {
    return authenticatedDelete(`/admin/plan/${plan_id}`);
  },
  getPlanDetails: (plan_id, addons = false) => {
    return authenticatedGet(`/admin/plan/${plan_id}?addons=${addons}`);
  },
  updatePlanCompatibility: (plan_id, compatible_base_plans) => {
    return authenticatedPut(`/admin/plan/${plan_id}/compatibility`, { compatible_base_plans });
  },
  addCodeToPlanItem: (plan_item_id, code_id, is_primary) => {
    return authenticatedPost(`/plan_item/${plan_item_id}/code`, { code_id, is_primary });
  },
  createPlanItemLabel: (data) => {
    return authenticatedPost(`/label`, data);
  },
  getLabels: () => {
    return authenticatedGet(`/labels`);
  },

  // Invoices
  getInvoices: (vet_id) => {
    return authenticatedGet(`/admin/vet/${vet_id}/invoices`);
  },
  getInvoiceDetails: (invoice_id) => {
    return authenticatedGet(`/admin/invoice/${invoice_id}`);
  },
  getPaymentRequests: (vet_id) => {
    return authenticatedGet(`/admin/vet/${vet_id}/payment_requests`);
  },
  getPaymentRequestPreview: (id) => {
    return authenticatedGet(`/payment_request/${id}/preview`);
  },
  processPaymentRequest: (id) => {
    return authenticatedPost(`/payment_request/${id}/process`);
  },
  updatePaymentRequest: (id, data) => {
    return authenticatedPatch(`/v2/payment_request/${id}`, data);
  },

  // Plan instances
  getPlanInstances: (vet_id) => {
    return authenticatedGet(`/admin/vet/${vet_id}/plan_instances`);
  },
  getPlanInstance: (id) => {
    return authenticatedGet(`/admin/plan_instance/${id}`);
  },
  getPlanInstanceUsage: (id) => {
    return authenticatedGet(`/admin/plan_instance/${id}/usage`);
  },
  getPlanInstanceUsageV2: (id) => {
    return authenticatedGet(`/plan_instance/${id}/usage`);
  },
  applyPlanItem: (invoice_item_id, plan_item_id) => {
    return authenticatedPatch(`/admin/invoice_item/${invoice_item_id}`, { plan_item_id });
  },
  createPlanInstance: (data) => {
    return authenticatedPost(`/admin/plan_instance`, data);
  },
  deletePlanInstance: (plan_id) => {
    return authenticatedDelete(`/admin/plan_instance/${plan_id}`);
  },
  getPatient: (id) => {
    return authenticatedGet(`/admin/patient/${id}`);
  },
  getInvitations: (vet_id) => {
    return authenticatedGet(`/admin/vet/${vet_id}/invitations`);
  },
  // Promos
  getPromos: (vet_id) => {
    return authenticatedGet(`/admin/vet/${vet_id}/promos`);
  },
  getPromo: (promo_id) => {
    return authenticatedGet(`/admin/promo/${promo_id}`);
  },
  deactivatePromo: (promo_id) => {
    return authenticatedPatch(`/admin/promo/${promo_id}`, { is_active: false });
  },
  createPromo: (vet_id, body) => {
    return authenticatedPost(`/admin/vet/${vet_id}/promo`, body);
  },
  createPromoEligibility: (promo_id, email) => {
    return authenticatedPost(`/admin/promo/${promo_id}/eligibility`, { email });
  },
  deletePromoEligibility: (promo_id, client_id) => {
    return authenticatedDelete(`/admin/promo/${promo_id}/eligibility/client/${client_id}`);
  },

  // Public endpoints
  getVetBySlug: (vet_id) => {
    return get(`/vet/${vet_id}`);
  },

  getClientByEmail: (vet_id, email) => {
    return get(`/vet/${vet_id}/clients?email=${encodeURIComponent(email)}`)
  },

  clientSelfEnroll: (client, patient, plan, payment) => {
    const formattedPayload = common.formatSelfRegistrationPayload({client, patient, plan, payment});
    return post(`/self-enroll`, formattedPayload);
  },

  renewEnroll: (plan_instance_id, vet_id, addon_ids, card_id, start_at) => {
    return authenticatedPost(`/plan_instance/${plan_instance_id}/renew`, { vet_id, addon_ids, card_id, start_at });
  },

  getPublicPlanInstance: (guid) => {
    return get(`/plan_instance/${guid}`);
  },

  // Global alert config
  getAlertConfig: () => {
    return authenticatedGet(`/admin/alert_config`);
  },
  updateAlertConfig: (data) => {
    return authenticatedPut(`/admin/alert_config`, data);
  },

  // TEST DATA MANAGEMENT
  getClients: () => {
    return authenticatedGet(`/admin/clients`);
  },
  getVetClients: (vet_id) => {
    return authenticatedGet(`/admin/vet/${vet_id}/clients`);
  },
  createClient: (data) => {
    return authenticatedPost(`/admin/client`, data);
  },
  deleteClient: (client_id) => {
    return authenticatedDelete(`/admin/client/${client_id}`);
  },
  getPatients: () => {
    return authenticatedGet(`/admin/patients`);
  },
  createPatient: (data) => {
    return authenticatedPost(`/admin/patient`, data);
  },
  deletePatient: (patient_id) => {
    return authenticatedDelete(`/admin/patient/${patient_id}`);
  },
  getAllInvoices: () => {
    return authenticatedGet(`/admin/invoices`);
  },
  createInvoice: (data) => {
    return authenticatedPost(`/admin/invoice`, data);
  },
  deleteInvoice: (invoice_id) => {
    return authenticatedDelete(`/admin/invoices/${invoice_id}`);
  },
};

export default api;
