import { useOutletContext } from 'react-router-dom';
import GanymedePaymentCardDisplay from '../../components/ganymede/GanymedePaymentCardDisplay';
import { useEffect, useState } from 'react';
import 'react-progress-button/react-progress-button.css';
import { useFormContext } from 'react-hook-form';

function PaymentInformationStep() {
  const outletContext = useOutletContext();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(undefined);
  const { setValue, watch } = useFormContext();

  const {
    client,
    paymentMode,
    setPaymentMode,
    paymentMethod,
    setPaymentMethod,
  } = outletContext;

  // update payment method field on page load
  useEffect(() => {
    if (paymentMethod) {
      if (paymentMethod.external_id) {
        setValue(
          'paymentMethodRadio',
          client?.cards
            ?.findIndex(
              (card) => card.external_id === paymentMethod.external_id
            )
            ?.toString()
        );
      } else {
        setValue('paymentMethod', paymentMethod);
      }
    }
  }, []);

  // update payment method state with form values as they change
  const paymentMethodField = watch('paymentMethod');
  useEffect(() => {
    if (paymentMethodField) {
      setPaymentMethod(paymentMethodField);
    }
  }, [paymentMethodField]);


  return (
    <>
      <div className={'payment-footer'}>
        <GanymedePaymentCardDisplay
          className={'invoice-payment-card-display'}
          paymentMethods={client?.cards}
          selectedPaymentMethod={selectedPaymentMethod}
          onSelectPaymentMethod={setSelectedPaymentMethod}
          mode={paymentMode}
          setMode={setPaymentMode}
          setPaymentMethod={setPaymentMethod}
        />
      </div>
    </>
  );
}

export default PaymentInformationStep;
