import GanymedePageContainer from '../../components/ganymede/PageContainer';
import GanymedeKeyValueTable from '../../components/ganymede/KeyValueTable';
import CheckCircle from '../../assets/svgs/CheckCircle.svg';
import info from '../../assets/svgs/info.svg';
import GanymedePageFooter from '../../components/ganymede/PageFooter';
import { useOutletContext } from 'react-router-dom';
import common from '../../lib/common';
import DayJs from 'dayjs';

/**
 * This has 3 modes:
 * Payment request receipt mode (hold has been placed, but invoice hasn't been processed yet)
 * Failed payment (displays payment request, invoice info and invoice items taking into account changes made after payment processing, but membership benefits are "processing" and text says payment failed)
 * Receipt mode (displays original payment request, invoice info and invoice items even if changes were made after payment processing)
 */
function PaymentRequestReceiptView() {
  const { dagwood } = useOutletContext();

  if (!dagwood) return <></>;

  let formattedDate;
  let data = {};
  let tabulatedItems = {};
  let invoiceTotal;
  let allItemTotal;
  let coveredItemTotal = 0;
  const noPetsEnrolled = dagwood.patients.every(prPatient =>
    dagwood.client.patients.find(
      patient => patient.id === prPatient.id && !patient.plan_instance
    )
  );
  const paymentStatus = dagwood.receipt ? 'complete' : dagwood.payment && dagwood.payment.status === 'failed' ? 'failed' : 'pending';

  if (paymentStatus === 'complete') {
    formattedDate = DayJs(dagwood.receipt.invoice_date).format('MM/DD/YYYY');
    allItemTotal = dagwood.receipt.items.reduce(
      (accum, currVal) => accum + currVal.item_price,
      0
    );
    coveredItemTotal = dagwood.receipt.items
      .filter(item => item.plan_item_description)
      .reduce((accum, currVal) => accum + (currVal.item_price - currVal.client_obligation), 0);
    invoiceTotal = dagwood.receipt.invoice_amount;
    if (dagwood.receipt.items.length) {
      tabulatedItems = dagwood.receipt.items.reduce((agg, item) => {
        let description = item.item_description;
        if (dagwood.patients.length > 1) {
          description = `${item.patient_name} - ${description}`;
        }
        if (agg[description]) {
          let i = 2;
          while (!!agg[`${description} (${i})`]) {
            i++;
          }
          description = `${description} (${i})`;
        }

        item.covered = !!item.plan_item_description;
        agg[description] = (
          <>
            {item.covered && (
              <span className={'strikethrough'}>
                {common.pennyIntToDollarString(item.item_price)}
              </span>
            )}
            <span>
              {item.covered
                ? common.pennyIntToDollarString(item.client_obligation)
                : common.pennyIntToDollarString(item.item_price)}
            </span>
          </>
        );
        return agg;
      }, {});
    }

    data = {
      'Confirmation number': dagwood.external_id,
      'Payment method': `${dagwood.receipt.card_brand} ****${dagwood.receipt.card_last4}`,
      Client: `${dagwood.receipt.client_first_name} ${dagwood.receipt.client_last_name}`,
      'Patient(s)': dagwood.receipt.items
        .map(item => item.patient_name)
        .filter((v, i, a) => a.indexOf(v) === i)
        .join(', '),
      ...tabulatedItems,
    };
    if (invoiceTotal - allItemTotal > 0) {
      // Some places don't have tax, so tax would be $0.
      // Some systems include tax with the line item, so displaying "Tax: $0" is inaccurate.
      // Some systems have implicit tax (not available for us to sync), so we have to calculate it.
      data['Tax'] = common.pennyIntToDollarString(invoiceTotal - allItemTotal);
    }
  } else if (paymentStatus === 'failed') {
    formattedDate = DayJs(dagwood.invoice.date).format('MM/DD/YYYY');
    allItemTotal = dagwood.invoice.items.reduce((accum, currVal) => {
      return accum + currVal.price;
    }, 0);
    coveredItemTotal = dagwood.invoice.items
      .filter(item => item.plan_item)
      .reduce((accum, currVal) => accum + currVal.price, 0);
    invoiceTotal = dagwood.invoice.amount;
    if (dagwood.invoice.items) {
      tabulatedItems = dagwood.invoice.items.reduce((agg, item) => {
        let description = item.description;
        if (dagwood.patients.length > 1) {
          description = `${item.patient_name} - ${description}`;
        }
        if (agg[description]) {
          let i = 2;
          while (!!agg[`${description} (${i})`]) {
            i++;
          }
          description = `${description} (${i})`;
        }

        item.covered = !!item.plan_item;
        agg[description] = (
          <>
            {item.covered && (
              <span className={'strikethrough'}>
                {common.pennyIntToDollarString(item.price)}
              </span>
            )}
            <span>
              {item.covered ? '$0' : common.pennyIntToDollarString(item.price)}
            </span>
          </>
        );
        return agg;
      }, {});
    }

    data = {
      'Confirmation number': dagwood.external_id,
      'Payment method': `${dagwood.card.brand} ****${dagwood.card.last4}`,
      Client: `${dagwood.client.first_name} ${dagwood.client.last_name}`,
      'Patient(s)': dagwood.patients.map(p => p.name).join(', '),
      ...tabulatedItems,
    };
    if (invoiceTotal - allItemTotal > 0) {
      data['Tax'] = common.pennyIntToDollarString(invoiceTotal - allItemTotal);
    }
  } else {
    formattedDate = DayJs(dagwood.date).format('MM/DD/YYYY');
    invoiceTotal = common.dollarStringToPennyInt(dagwood.invoice_amount);
    data = {
      'Confirmation number': dagwood.external_id,
      'Payment method': `${dagwood.card.brand} ****${dagwood.card.last4}`,
      Client: `${dagwood.client.first_name} ${dagwood.client.last_name}`,
      'Patient(s)': dagwood.patients.map(p => p.name).join(', '),
    };
  }

  let subtitle;
  if (paymentStatus === 'complete') {
    subtitle = 'Your invoice has been successfully processed and paid.';
  } else if (paymentStatus === 'failed') {
    subtitle = 'Your payment for non-covered items failed. Our support team will attempt to get this resolved and may reach out to you.';
  } else if (dagwood.fundsHeld) {
    subtitle = `We have placed a hold on your credit card. Your care plan benefits, if any, will be applied to your invoice accordingly. We will send you a final, itemized receipt upon completion.`;
  } else {
    subtitle = `This payment is being processed. Your care plan benefits, if any, will be applied to your invoice accordingly. We will actually charge your card and send you a final, itemized receipt upon completion.`;
  }

  return (
    <>
      <div
        className={
          'subscription-flow flex flex-row items-center bg-white px-3 py-1.5 text-left md:px-10 md:py-3 lg:px-20 lg:py-6'
        }
      >
        <img
          className={'mr-2.5 w-20'}
          alt={dagwood.vet.name}
          src={dagwood.vet.logo_url}
        />
        <span className={'text-2xl font-normal sm:text-3xl md:text-4xl'}>
          {dagwood.vet.name}
        </span>
      </div>
      <GanymedePageContainer
        className={'receipt-container'}
        footerContent={
          <>
            <div className="border-x-0 border-b border-t-0 border-solid border-gray-200 text-xl font-semibold">
              <div className="flex flex-row justify-between pb-2">
                <div>Invoice Date</div>
                <div>{formattedDate}</div>
              </div>
              <div className="flex flex-row justify-between pb-2">
                <div>Invoice Total</div>
                <div className="flex flex-row justify-end">
                  <div
                    className={
                      coveredItemTotal > 0 &&
                      'pr-2 line-through decoration-green-500'
                    }
                  >
                    {common.pennyIntToDollarString(invoiceTotal)}
                  </div>
                  {coveredItemTotal > 0 && (
                    <div>
                      {common.pennyIntToDollarString(
                        dagwood.invoice || dagwood.receipt
                          ? invoiceTotal - coveredItemTotal
                          : 0
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-row justify-between pb-2">
                <div>Snout coverage</div>
                <div>
                  {noPetsEnrolled
                    ? 'Not enrolled'
                    : paymentStatus !== 'pending'
                    ? common.pennyIntToDollarString(coveredItemTotal)
                    : 'Processing'}
                </div>
              </div>
            </div>
            <GanymedeKeyValueTable
              className={'ganymede-full-width total'}
              data={data}
            />
          </>
        }
      >
        <div className={'flex flex-col px-10 pb-14 text-center'}>
          <span className={'flex flex-col'}>
            <img
              className={'h-32 sm:h-40'}
              src={paymentStatus !== 'failed' ? CheckCircle : info}
              alt={'success icon'}
            />
          </span>
          {paymentStatus !== 'failed' ?
            <span
              className={
                'font-roboto-slab flex flex-col text-3xl font-normal sm:text-4xl'
              }
            >
              Thank you!
            </span>
          : <span
              className={
                'flex flex-col font-normal text-3xl sm:text-4xl font-roboto-slab'
              }
            >
              Payment failed
            </span>
          }
          <span className={'mt-5 mb-2.5 flex flex-col text-base text-gray-500'}>
            {subtitle}
          </span>
        </div>
      </GanymedePageContainer>
      <GanymedePageFooter />
    </>
  );
}

export default PaymentRequestReceiptView;
