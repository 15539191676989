import { useForm } from 'react-hook-form';
import RadioButton from '../../../components/RadioButton/RadioButton.js'
import StepContainer from "../StepContainer";
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../../constants';

function SelectPet({state, dispatch}) {

  const { formState: { errors }, handleSubmit, register } = useForm({
  });

  const onSubmit = (values) => {
    track(AMPLITUDE_EVENT_CODES.SELF_ENROLL_SELECT_PET, {
      vet: state.vet,
      client: state.client,
      pet: values.selectedPet,
    });
    dispatch({type: 'next', selectedPet: state?.applicablePatients?.find((patient) => values.selectedPet === patient?.id?.toString() ) });
  }

  const enrolledPets = state?.applicablePatients?.filter(pet => pet.plan_instance && ['ok', 'overdue'].includes(pet.plan_instance.payment_status));
  const ineligiblePets = state?.applicablePatients?.filter(pet => !['dog', 'cat'].includes(pet.species));
  const eligiblePets = state?.applicablePatients?.filter(pet =>
    !enrolledPets.map(pet => pet.id).includes(pet.id) && !ineligiblePets.map(pet => pet.id).includes(pet.id));

  return (
    <StepContainer back={() => dispatch({type: 'prev'})}>
      <form className={'flex flex-col items-center h-full'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'font-roboto-slab text-3xl sm:text-4xl text-center mb-4'}>Which pet would you like to enroll?</div>
        <div className="w-full grid grid-cols-1 grid-row-gap-2">
          {eligiblePets.map((pet, key) => (
              <RadioButton key={key} label={pet.name[0].toUpperCase() + pet.name.slice(1)} id={`radio-${pet.id}`} name={`radio=${pet.id}`} value={pet.id} {...register('selectedPet', { shouldUnregister: true, required: { value: true, message: 'Response is required' }})}/>
          ))}
          <RadioButton label={"A pet that is not listed here"} id={ 'radio-newPet'} name={ 'radio-newPet'} value={'newPet'} {...register('selectedPet', { shouldUnregister: true, required: { value: true, message: 'Response is required' }})}/>
          <div className='text-stone-500 mt-4'>{enrolledPets.length > 0 && `Pets already enrolled in plans (${enrolledPets.length})`}</div>
          {enrolledPets.map((pet, key) => (
            <div key={key} className='wizard flex items-center text-l text-stone-400 px-3 py-3 rounded-md bg-white opacity-60 border border-solid border-stone-200'><span className="wizard w-3 h-3 inline-block mr-2 rounded-full bg-stone-200 ring-stone-200 ring-offset-2 ring-1 border-solid border-stone-200 flex-no-shrink"></span>{pet.name[0].toUpperCase() + pet.name.slice(1)}</div>
          ))}
          <div className='text-stone-500 mt-4'>{ineligiblePets.length > 0 && `Ineligible pets (${ineligiblePets.length})`}</div>
          {ineligiblePets.map((pet, key) => (
            <div key={key} className='wizard flex items-center text-l text-stone-400 px-3 py-3 rounded-md bg-white opacity-60 border border-solid border-stone-200'><span className="wizard w-3 h-3 inline-block mr-2 rounded-full bg-stone-200 ring-stone-200 ring-offset-2 ring-1 border-solid border-stone-200 flex-no-shrink"></span>{pet.name[0].toUpperCase() + pet.name.slice(1)}</div>
          ))}
        </div>

        <div className={'mt-2 form-validation-error'}>{errors?.selectedPet?.message}</div>
        <button className={'mt-auto sm:mt-8 w-full sm:w-40 btn-primary' } type="submit">Continue</button>
      </form>
    </StepContainer>
  );
}

export default SelectPet;
