import { useOutletContext } from "react-router-dom";
import { Controller, useFormContext } from "react-hook-form";
import "react-progress-button/react-progress-button.css";
import { useRef, useState } from "react";
import GanymedeInput from "../../components/ganymede/GanymedeInput";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import api from "../../services/jupiter_api";
import parsePhoneNumber, { isPossiblePhoneNumber } from "libphonenumber-js";
import Tooltip from "../../components/ganymede/Tooltip";
import { ErrorMessage } from "@hookform/error-message";
import Info from "../../assets/svgs/info-icon.svg"

const ClientOption = ({
  innerRef,
  innerProps,
  value: { first_name, last_name, email, phone },
  ...props
}) => {
  return (
    <div
      className={`client-select-option ${props?.className || ""}`}
      ref={innerRef}
      {...innerProps}
    >
      <div>
        {first_name} {last_name}
      </div>
      <div>
        <div>{email}</div>
        {phone && <div>&nbsp;•&nbsp;</div>}
        {phone && <div>{parsePhoneNumber(phone)?.formatNational()}</div>}
      </div>
    </div>
  );
};

const ClientMenuList = (props) => {
  const { MenuListHeader = null, ClientMenuListFooter = null } =
    props.selectProps.components;

  return (
    <components.MenuList {...props}>
      {props.children.length && MenuListHeader}
      {props.children}
      {props.children && ClientMenuListFooter}
    </components.MenuList>
  );
};

const ClientMenuListFooter = ({ onClick }) => (
  <div className={"client-select-footer"}>
    <button type={"button"} onClick={onClick}>
      + Add a New Client
    </button>
  </div>
);

//This is the version I found online at https://emailregex.com/.
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function ClientInformationStep() {
  const outletContext = useOutletContext();

  const {
    watch,
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const clientEmail = watch('client.email');

  const { doClientSearch, clientMode, setClientMode, emailIsFree } = outletContext;

  const clientSearchSelectRef = useRef();
  const [clientOptions, setClientOptions] = useState([]);
  const [showSelectedClient, setShowSelectedClient] = useState(true);

  let timeout;
  const asyncClientOptions = (inputValue) =>
    new Promise(async (resolve) => {
      if (inputValue?.trim()?.length) {
        // debounce search when user stops typing
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(async () => {
          const searchResults = await doClientSearch(inputValue);
          setClientOptions(
            searchResults?.map((client) => {
              return {
                label: <ClientOption value={client} />,
                value: client,
              };
            })
          );
          resolve(
            searchResults.map((client) => {
              return {
                label: <ClientOption value={client} />,
                value: client,
              };
            })
          );
        }, 400);
      }
    });

  // Transform and Parse section of react hook api here: https://react-hook-form.com/advanced-usage
  const transform = {
    input: (value) => {
      return value
        ? { label: <ClientOption className={"label"} value={value} />, value }
        : value;
    },
    output: (e) => {
      return e?.value;
    },
  };

  return (
    <>
      <div className={"client-information-container"}>
        {clientMode === "select" && (
          <div className={"client-select-container"}>
            <Controller
              name="client"
              control={control}
              rules={{
                value: null,
                required: {
                  value: true,
                  message: "client selection is required",
                },
              }}
              render={({ field }) => (
                <>
                  <AsyncSelect
                    blurInputOnSelect
                    controlShouldRenderValue={showSelectedClient}
                    onFocus={() => setShowSelectedClient(false)}
                    onBlur={(e) => {
                      field.onBlur(e);
                      setShowSelectedClient(true)
                    }}
                    inputId={"client-select-input"}
                    styles={{
                      menuList: (provided) => ({
                        ...provided,
                        paddingBottom: 0,
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        '*': {
                          boxShadow: 'none !important',
                        }
                      })
                    }}
                    value={transform.input(field.value)}
                    placeholder={"Type to search for client..."}
                    ref={clientSearchSelectRef}
                    components={{
                      Option: ClientOption,
                      MenuList: ClientMenuList,
                      ClientMenuListFooter: (
                        <ClientMenuListFooter
                          onClick={() => {
                            setClientMode("new");
                          }}
                        />
                      ),
                    }}
                    defaultOptions={clientOptions}
                    loadOptions={asyncClientOptions}
                    onChange={(e) => {
                      field.onChange(transform.output(e));
                    }}
                    noOptionsMessage={() => "No clients found"}
                  />
                  {errors?.[field.name] && (
                    <span className={"form-validation-error"}>
                      {errors?.[field.name]?.message}
                    </span>
                  )}
                </>
              )}
            />
          </div>
        )}
        {clientMode === "new" && (
          <>
            <div
              className={
                "d-grid grid-col-gap-2 grid-cols-6"
              }
            >
              <GanymedeInput
                className={"grid-span-3"}
                label={"First Name"}
                errors={errors}
                type="text"
                {...register("client.first_name", {
                  required: { value: true, message: "First name is required" },
                })}
              />
              <GanymedeInput
                className={"grid-span-3"}
                label={"Last Name"}
                errors={errors}
                type="text"
                {...register("client.last_name", {
                  required: { value: true, message: "Last name is required" },
                })}
              />

              <div className={"grid-span-3 email-container"}><GanymedeInput
                type='text'
                label={"Email"}
                errors={errors}
                // A WARNING FROM KYLE -
                // see SNO-878 and talk to me before you start messing with this; here there be dragons!
                // this validation is duplicated in WellnessViewController!
                // search for "SNO-878" to find the relevant code over there
                {...register("client.email", {
                  required: { value: true, message: "Email is required" },
                  pattern: {
                    value: emailRegex,
                    message: "Email is invalid",
                  }})}
                customError={
                  <ErrorMessage
                errors={errors}
                name="client.email"
                render={({message}) => <div><span className={'form-validation-error'}>{message}</span></div>}
              />
                }
              />
              { (emailRegex.test(clientEmail) && emailIsFree === false) && (
                <Tooltip
                title="This email address is already associated with a client in your system."
                content='To invite an existing client, click the "back to search" button below and try searching for the client by name or email address.'
              ><div className="form-validation-error">Email is associated with an existing client.<img className="info-symbol" src={Info}/></div></Tooltip>
              )}
              </div>
              <div className={"grid-span-3"}><GanymedeInput

                label={"Phone"}
                errors={errors}
                {...register("client.phone", {
                  validate: (value) => {
                    if (!value) return true; //phone isn't technically required
                    return (
                      isPossiblePhoneNumber(value, "US") ||
                      "invalid phone number"
                    );
                  },
                })}
              />
            </div>
            </div>
          </>
        )}

      </div>
    </>
  );
}

export default ClientInformationStep;
