import GanymedePageContainer from "../../components/ganymede/PageContainer";
import GanymedeKeyValueTable from "../../components/ganymede/KeyValueTable";
import CheckCircle from "../../assets/svgs/CheckCircle.svg";
import GanymedePageFooter from "../../components/ganymede/PageFooter";
import DayJs from 'dayjs';
import { useEffect, useState } from 'react';
import api from "../../services/jupiter_api";
import { useParams } from 'react-router-dom';
import common from '../../lib/common';

function PlanReceipt() {

  const params = useParams()
  const planInstanceId = params.guid;
  const [dagwood, setDagwood] = useState();
  const [receiptRows, setReceiptRows] = useState();
  const [totalPaidToday, setTotalPaidToday] = useState();

  useEffect(() => {
    (async () => {
      try {
        const plan = await api.getPublicPlanInstance(planInstanceId);

        // Price calculations
        const monthlyPrice = common.dollarStringToPennyInt(plan?.plan.monthly_price);
        const effectiveMonthlyPrice = plan?.promo ?
          plan?.promo.core_plan_monthly_price || Math.round(monthlyPrice * (100 - plan?.promo.subsidy_percentage) / 100)
          : monthlyPrice;
        plan.addons.forEach(addon => {
          addon.effectiveMonthlyPrice = plan?.promo ? Math.round(addon.monthly_price * (100 - plan?.promo.subsidy_percentage) / 100) : addon.monthly_price
        });
        const effectiveAddonMonthlyPrice = plan.addons.reduce((accum, addon) => accum + addon.effectiveMonthlyPrice, 0);

        setTotalPaidToday(common.pennyIntToDollarString(effectiveMonthlyPrice
          + plan?.plan.enrollment_fee
          + effectiveAddonMonthlyPrice));

        // Receipt table rows
        const cfg = {
          'Confirmation Number': plan?.external_id,
          'Payment Method': `${plan?.card?.brand} ****${plan?.card?.last4}`,
          'Client': `${plan?.client?.first_name} ${plan?.client?.last_name}`,
          'Patient': plan?.patient?.name,
          'Plan': plan?.plan?.name,
          'Enrollment Date': DayJs(plan?.start_at)?.format('MM/DD/YYYY'),
        }
        if (plan.plan.enrollment_fee) {
          cfg['Enrollment Fee'] = common.pennyIntToDollarString(plan.plan.enrollment_fee);
        }
        if (plan.promo) {
          cfg['Promo applied'] = plan.promo.name;
        }
        cfg[`${plan.plan.name} plan - Monthly Payment`] = common.pennyIntToDollarString(effectiveMonthlyPrice);
        for (const addon of plan.addons) {
          cfg[`${addon.name} addon - Monthly Payment`] = common.pennyIntToDollarString(addon.effectiveMonthlyPrice);
        }
        setReceiptRows(cfg);
  
        setDagwood(plan);
      } catch (e) {
        console.log(e)
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={'subscription-flow flex flex-row text-left items-center px-3 py-1.5 md:px-10 md:py-3 lg:px-20 lg:py-6 bg-white'}>
        <img className={'w-20 mr-2.5'} alt={dagwood?.vet?.name} src={dagwood?.vet?.logo_url} /><span className={'text-2xl sm:text-3xl md:text-4xl font-normal'}>{dagwood?.vet?.name}</span>
      </div>
      <GanymedePageContainer
        className={'receipt-container'}
        footerContent={
          (<>
            <GanymedeKeyValueTable
              className={'ganymede-full-width total last:[&_th]:text-right'}
              header={['Total Paid Today', totalPaidToday || '-']}
              data={receiptRows} />
          </>)
        }
      >
        <div className={'flex flex-col text-center px-10 pb-14'}>
          <span className={'flex flex-col'}>
            <img className={'h-32 sm:h-40'} src={CheckCircle} alt={'success icon'} />
          </span>
          <span className={'flex flex-col font-normal text-3xl sm:text-4xl font-roboto-slab'}>Thank You!</span>
          <span className={'flex flex-col text-base text-gray-500 mt-5 mb-2.5'}>{dagwood?.patient?.name} has been successfully enrolled in a care plan!</span>
        </div>
      </GanymedePageContainer>
      <GanymedePageFooter />
    </>
  );
}

export default PlanReceipt;
