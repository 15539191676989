import {useOutletContext} from "react-router-dom";
import GanymedeKeyValueTable from "../../components/ganymede/KeyValueTable";
import InvoiceColumnHeader from "../../components/ganymede/InvoiceColumnHeader";
import GanymedePageContainer from "../../components/ganymede/PageContainer";
import GanymedeDocumentHeader from "../../components/ganymede/DocumentHeader";
import GanymedePageFooter from "../../components/ganymede/PageFooter";
import common from "../../lib/common";
import SmallerCheckCircle from '../../assets/svgs/SmallerCheckCircle.svg';
import parsePhoneNumber from 'libphonenumber-js';

function PlanChooserView() {

  const { choosePlan, dagwood } = useOutletContext();

  if (dagwood.vet.has_core_plan) {
    window.location.href = `https://admin.snoutplans.com/enroll/${dagwood.vet.slug}`;
    return(<></>);
  }

  async function onClick (planIndex) {
    await choosePlan(planIndex);
  }

  function plansToKvtData (plans) {
    const data = {};  // maps plan item descriptions to an array containing [price, ...(checkmarks or '-')]
    const newPlans = structuredClone(plans);  // Because we're rolling plan items into display groups

    for (const plan of newPlans) {
      // Group each item by its display group, or if it's not part of a display group, then use its description
      plan.items = plan.items.reduce((acc, item) => {
        const name = item.display_group || item.description;
        if (!acc[name]) {
          acc[name] = [];
        }
        acc[name].push(item);
        return acc;
      }, {});

      // Merge items within display groups
      plan.items = Object.keys(plan.items).map(name => {
        return plan.items[name].reduce((acc, item) => {
          if (acc) {
            acc.description = name;
            acc.priority = Math.min(acc.priority, item.priority);
            acc.list_price += item.list_price;
            acc.codes = acc.codes.concat(item.codes);
            acc.max_quantity = 1;
            return acc;
          }
          else {
            return item;
          }
        }, null);
      });
    }

    // Combines all plan items, dedupes by description, sorts by priority
    const allItems = newPlans.map(plan => plan.items);
    const uniqueItems = [].concat(...allItems)
      .filter((item) => !item.is_hidden)
      .filter((value, index, arr) => arr.findIndex(value2 => value2.description === value.description) === index)
      .sort((item1, item2) => item1.priority - item2.priority);

    // Initialize all items in order
    for (const item of uniqueItems) {
      data[item.description] = [];
    }

    // Add either a dash or checkmark to each plan's column
    for (const description of Object.keys(data)) {
      for (const plan of newPlans) {
        const item = plan.items.find(item => item.description === description);
        if (item) {
          data[description].push(<div className={'justify-center'} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><img src={SmallerCheckCircle} alt={'included'} /></div>);
        }
        else {
          data[description].push(<div className={'justify-center'} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>-</div>);
        }
      }
    }
    return data;
  }

  if (!dagwood.plans) return <></>;

  return (
      <>
        <div className={'subscription-flow flex flex-row text-left items-center px-3 py-1.5 md:px-10 md:py-3 lg:px-20 lg:py-6 bg-white practice-information-header'}>
          <img className={'w-20 mr-2.5'} alt={dagwood?.vet?.name} src={dagwood?.vet?.logo_url} /><span className={'text-2xl sm:text-3xl md:text-4xl font-normal'}>{dagwood?.vet?.name}</span>
        </div>
        <GanymedePageContainer className={'subscription-container'}>
          <div className={'mb-4 sm:mb-8'}>
            <GanymedeDocumentHeader
                title={`${dagwood.patient.name}'s Care Plan Options`}
                attribution={(
                    <>
                      {[dagwood.vet.name, dagwood.vet.street_address, dagwood.vet.street_address2, `${dagwood.vet.city}, ${dagwood.vet.state} ${dagwood.vet.postal_code}`, parsePhoneNumber(dagwood.vet.phone, 'US')?.formatNational()].map((attr, index) => (<span key={index}>{attr}</span>))}
                    </>
                )}
            />
          </div>
          <div className={'flex flex-wrap md:flex-nowrap mb-8'}>
            <GanymedeKeyValueTable className={'w-full [&_td]:align-baseline'} header={ 'Pet Details' } data={{ 'Pet Name':dagwood.patient.name, 'Species':dagwood?.patient?.species?.charAt(0).toUpperCase() + dagwood?.patient?.species?.slice(1) }}/>
            <GanymedeKeyValueTable className={'md:ml-16 mt-4 md:mt-0 w-full [&_td]:align-baseline'} header={ 'Owner Details' } data={{ 'Owner Name':`${dagwood.client.first_name} ${dagwood.client.last_name}` }}/>
          </div>
          {/* display side by size plan choooser on medium devices and up*/}
          { dagwood.vet.plans_details_pdf_url && 
            <div style={{float:'right', marginBottom:'1rem'}}>
              See what's included in each plan <a target="_blank" href={dagwood.vet.plans_details_pdf_url}>here</a>.
            </div>
          }
          <div>
            <GanymedeKeyValueTable
                className={'GanymedeLarge chooser mt-8 w-full'}
                header={[
                  (<span>{dagwood.patient.name}'s Care Plan<br/></span>),
                  ...dagwood.plans.map(p => (
                      <InvoiceColumnHeader
                          labelTop={`${p.name}`}
                          labelBottom={common.getSavings(p) ? `${common.getSavings(p)} annual savings` : ''}
                          value={common.pennyIntToRoundedDollarString(p.monthly_price)}
                          subscript={`/month${p.enrollment_fee > 0 ? "*" : ""}`}
                      />
                  ))
                ]}
                suppressBody={true}
                data={plansToKvtData(dagwood.plans)}
                trailer={[
                  (<></>),
                  ...dagwood.plans.map((plan, planIndex) => (<div className={'flex justify-center align-middle'}><button onClick={() => {onClick(planIndex);}}>Select Plan</button></div>)),
                ]}
            />
            {/* this assumes all plans automatically renew after twelve months */}
            {dagwood?.plans?.[0]?.enrollment_fee > 0 && <div
              className={'enrollment-fee-text'}>* There is a {common.pennyIntToRoundedDollarString(dagwood?.plans?.[0]?.enrollment_fee)} enrollment fee. A twelve month membership is required and set to automatically renew each year.</div>}
          </div>

          
        </GanymedePageContainer>
        <GanymedePageFooter />
      </>
  );
}

export default PlanChooserView;
